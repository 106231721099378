:root {
  --primary-color: #DA1F24
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins";
}

.sidebar-expanded {
    width: 16%;
    transition: width 0.8s;
  }
            
  .sidebar-collapsed {
    width: 4%; 
    transition: width 0.8s;
  }

  .data-\[state\=checked\]\:bg-primary[data-state=checked]{
    background-color: var(--primary-color);
  }