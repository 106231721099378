.company_link {
  text-decoration: none;
  display: flex;
  align-items: center;
  flex: 2;
}
.company_details.dark {
  color: whitesmoke;
}
.company_image {
  max-width: 100px;
}
.companycard_logo img {
  max-width: 200px;
}
.companycard_logo_img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.company_card {
  display: flex;
  position: relative;
  align-items: center;
  margin: 10px;
  /* background: var(--card-color); */
  border-radius: 5px;
  padding: 5px 10px;
  justify-content: space-between;
}

.companiesbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.company_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--text-color);
}
.company_profile {
  padding: 15px 40px;
}
.companycard_logo {
  display: flex;
  align-items: center;
}
.company_top_logo {
  background-color: var(--card-color);
  padding: 15px;
  border-radius: 15px;
}
.companycard_info {
  margin-left: 20px;
}
.companycard_info h3,
.company_type h3 {
  font-size: 1.4rem;
}
.company_desc h2 {
  margin: 25px 0 10px 0;
}
.company_desc {
  margin-bottom: 15px;
}
.company_header h2 {
  flex: 3;
  text-align: center;
}
.company_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
}
.button {
  display: flex;
  align-items: center;
}
.button button:nth-child(1) {
  margin-right: 15px;
}
.company_btn {
  padding: 8px 16px;
}
.contact_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5px;
}
.pt_contact {
  margin-top: 20px;
}
.hires {
  display: flex;
  align-items: center;
}
.hires p {
  margin: 0 5px;
}
.company_top_card {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
}
.companycard_info,
.company_type {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.companycard_info p span {
  font-weight: bold;
}
.company_type a {
  color: var(--text-color);
  text-decoration: none;
}
.company_type a:hover {
  text-decoration: underline;
}
textarea {
  resize: none;
  font-size: 1.1rem;
  max-width: 100%;
}
.left_side_button label {
  padding: 5px 10px;
  background: black;
  color: white;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
}

.menu_list {
  background: var(--content-color);
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 210px;
  z-index: 10;
}
.menu_item {
  width: 200px;
}
.lis {
  color: var(--text-color);
  text-decoration: none;
}
.lis:hover {
  color: var(--primary-color);
}
.backbutton {
  color: var(--text-color);
}
.invitation_container {
  background: var(--content-color);
  min-height: 100vh;
  padding: 15px;
  transition: var(--tran-03);
}
.invitation_content_header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.invitation_container.dark {
  background: var(--content-color);
}
.invitation_container.close {
  width: calc(100vw - 255px);
}
.table_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.table_invitation {
  border-collapse: collapse;
}

.table_invitation thead {
  background: var(--sidebar-color);
  color: var(--text-color);
}
.table_invitation thead tr th {
  font-size: 1.2rem;
  font-weight: medium;
  letter-spacing: 0.35px;
  opacity: 1;
  padding: 12px;
  vertical-align: top;
  border: 1px solid black;
}
.table_invitation tbody tr td {
  font-size: 1rem;
  letter-spacing: 0.35px;
  font-weight: normal;
  background: var(--card-color);
  padding: 16px;
  color: var(--text-color);
  text-align: center;
  border: 1px solid black;
}
.about_container h3{
  font-size: 1.1rem;
}
.about_container a{
  text-decoration: none;
  
}
@media (max-width: 1150px) {
  .companiesbox {
    grid-template-columns: repeat(1, 1fr);
  }
  .companiesbox.close {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 768px) {
  .company_top_card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .companycard_logo {
    flex-direction: column;
  }
  .companycard_info {
    margin: 15px;
  }
  .companycard_info,
  .company_type {
    align-items: center;
  }
}
@media (max-width: 620px) {
  .company_profile {
    padding: 15px;
  }
  .invitation_content_header{
    justify-content: space-between;
  }
}
@media (max-width: 550px) {
  .company_details h2 {
    font-size: 1.1rem;
  }
  .hires {
    margin-top: 10px;
  }
  .contact_box {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 509px) {
  .left_side_button {
    display: flex;
    flex-direction: column;
  }
  .left_side_button label {
    margin: 10px 0;
  }
}
@media (max-width: 467px) {
  .table_invitation thead {
    display: none;
  }
  .table_invitation,
  .table_invitation tbody,
  .table_invitation tr,
  .table_invitation td {
    display: block;
    width: 100%;
  }
  .table_invitation tr {
    margin-bottom: 15px;
  }
  .table_invitation tbody tr td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }
  .table_invitation td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }
}

@media (max-width: 400px) {
  .right_side_button {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 390px) {
  .company_top_card {
    margin-top: 15px;
  }
}
