.container {
  background: var(--body-color);
  transition: var(--tran-04);
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding: 10px 14px;
  width: 250px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  z-index: 150;
}
.sidebar.close {
  width: 88px;
  transition: var(--tran-05);
}
.close_icon {
  position: absolute;
  right: -25px;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  color: var(--text-color);
  background: white;
  padding: 5px;
  border-radius: 5px;
}
.sidebar.close .text {
  opacity: 0;
}
.sidebar .header-text {
  margin-left: 40px;
}
.sidebar li {
  height: 50px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  border-radius: 5px;

  /* justify-content: center; */
}
.sidebar li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 20px;
  color: var(--text-color);
}
.sidebar .list {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 6px;
  transition: var(--tran-02);
}
.sidebar .list:hover {
  background: var(--primary-color);

}
.sidebar .list:hover .icon,
.sidebar .list:hover .text {
  color: var(--sidebar-color);
}
.list.sidebar_active {
  background: var(--primary-color);
}
.list.sidebar_active .icon,
.list.sidebar_active .text {
  color: var(--sidebar-color);
}
.dark .sidebar .list:hover .icon,
.dark .sidebar .list:hover .text {
  color: var(--text-color);
}
.sidebar .text {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-color);
  transition: var(--tran-03);
  white-space: nowrap;
  opacity: 1;
}
.image h1 {
  text-align: center;
  width: 100%;
  font-size: 1.6rem;
}
.sidebar_logo{
  font-size: 2rem;
}
.sidebar .image {
  min-width: 50px;
  display: flex;
  align-items: center;
  background: var(--primary-color);
  color: white;
  border-radius: 5px;
}
.sidebar header {
  position: relative;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}
.sidebar .menu-bar {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 0;
}
.menu-bar .mode {
  position: relative;
  border-radius: 5px;
  background: var(--primary-color-light);
  cursor: pointer;
}
.sidebar li:hover{
  background: var(--primary-color);
}
.sidebar li:hover .icon,
.sidebar li:hover .text{
  color: var(--profile-color);
}
.menu-bar .mode .moon-sun {
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
}
.menu-bar .mode .toggle-icons {
  position: absolute;
}
.logout{
  cursor: pointer;
}
.dark {
  --body-color: #18191a;
  --sidebar-color: #0A192F;
  --primary-color: #5CE9CB;
  --content-color:#052035;
  --primary-color-light: #172A46;
  --card-color:#172A46;
  --profile-color:black;
  --toggle-color: #fff;
  --text-color: #ccc;
}

/* From uiverse.io by @satyamchaudharydev */
/* The switch - the box around the slider */
.switch {
  --width-of-switch: 3.5em;
  --height-of-switch: 2em;
  /* size of sliding icon -- sun and moon */
  --size-of-icon: 1.4em;
  /* it is like a inline-padding of switch */
  --slider-offset: 0.3em;
  position: relative;
  width: var(--width-of-switch);
  height: var(--height-of-switch);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f5;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: var(--size-of-icon,1.4em);
  width: var(--size-of-icon,1.4em);
  border-radius: 20px;
  left: var(--slider-offset,0.3em);
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(40deg,#ff0080,#ff8c00 70%);
  ;
 transition: .4s;
}

input:checked + .slider {
  background-color: #303136;
}

input:checked + .slider:before {
  left: calc(100% - (var(--size-of-icon,1.4em) + var(--slider-offset,0.3em)));
  background: #303136;
  /* change the value of second inset in box-shadow to change the angle and direction of the moon  */
  box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -4px 0 0 #a3dafb;
}

@media(max-width:590px) {
  .sidebar.close{
    width: 0px;
    overflow: hidden;
    transform: translateX(-100%);
  }
}