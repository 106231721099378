
.TabsRoot {
  display: flex;
  flex-direction: column;
}

.TabsList {
  display: flex; 
}

.TabsTrigger {
  font-family: inherit;
  background-color:#D9888A;
  padding: 0 20px;
  height: 3.5rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: white;
  user-select: none;
  border:1px solid rgba(0, 0, 0, 0.15);
}
.TabsTrigger:first-child {
  border-top-left-radius: 15px;
}
.TabsTrigger:last-child {
  border-top-right-radius: 15px;
}
.TabsTrigger:hover {
  color: white;
  background-color: var(--primary-color);
  cursor: pointer;
}
 .TabsTrigger[data-state='active'] {
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
}

.TabsContent {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  height: 25rem;
}


