.login_container,.forgot_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}
.login_page,.forgot_page {
  min-width: 23%;
  min-height: 65%;
  border-radius: 10px;
  position: relative;
  padding: 25px 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.login_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.login_form {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.login_form h2 {
  font-size: 2rem;
  color: var(--text-color);
}
.mockup_img {
  max-width: 300px;
}
.avatar_img {
  width: 175px;
  min-height: 45px;
}
.wave_img {
  position: absolute;
  left: -72%;
  transform: rotate(80deg);
}
.p {
  margin: 20px 0;
  color: var(--text-color);
}
.input_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.user_icons {
  color: var(--text-color);
}
.reg_link {
  text-decoration: none;
  color: var(--text-color);
}
.reg_link:hover {
  color: rgb(0, 140, 255);
}
.input_box input {
  outline: none;
  width: 250px;
  padding: 8px 15px;
  background: transparent;
  font-size: 1.1rem;
  color: var(--text-color);
}
@media (max-width: 1024px) {
  .wave_img {
    left: -77%;
  }
}
@media (max-width: 768px) {
  .mockup {
    display: none;
  }
}
@media (max-width: 425px) {
  .login_page {
    min-width: 65%;
    padding: 10px 25px;
  }
}

.ac {
  color: var(--primary-color);
  font-size: 1rem;
}
.option_container {
  height: 100vh;
  background-image: url("../images/bg.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.option_card {
  width: 300px;
  height: 300px;
  background: var(--sidebar-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  position: relative;
  padding: 15px;
}
.student_login,
.instituiton_login {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  color: whitesmoke;
  background-color: var(--primary-color);
  border-radius: 15px;
  padding: 5;
}
.student {
  width: 100%;
  text-decoration: none;
}
.student_login h2,
.instituiton_login h2 {
  font-weight: 500;
}
.line {
  width: 100%;
  height: 2px;
  background-color: rgb(224, 223, 223);
}
.student_icon {
  font-size: 1.3rem;
}
